<script>
import DevicesService from '@/service/devices.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  name: 'modal-view-user-face-device',

  data() {
    return {
      dialog: false,
      loading: false,
      device: { name: '' },
      user: { name: '' },
      photo: undefined,
    };
  },

  created() {
    this.$on('viewUserFaceDevice', this.viewUserFaceDevice);
  },

  methods: {
    viewUserFaceDevice({ device, user }) {
      this.device = device;
      this.user = user;
      this.photo = undefined;
      this.fetch();
      this.dialog = true;
    },

    async fetch() {
      this.loading = true;

      try {
        const face = await DevicesService.getUserFaceInDevice(this.device.id, this.user.userId);
        if (face) this.photo = face[0];
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" max-width="600px" :persistent="loading">
    <v-card>
      <v-card-title>
        <span class="text-h5">Face do usuário {{ user.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-img v-if="photo" :src="`data:image/png;base64,${photo}`" />
          <!-- <v-avatar v-else color="indigo" size="99">
            <v-icon x-large> mdi-account-circle </v-icon>
          </v-avatar> -->
          <span v-else> nenhuma imagem para exibir </span>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
